<template>
  <div>
    <div class="account-section">

      <div class="text-center my-1">
        <h3 class="mb-0 font-weight-bolder text-primary">
          กงล้อรับโชค
        </h3>
      </div>

      <div class="panel">
        <div align="center">
          <div
            id="screenshot-section"
            class="the_wheel"
          >
            <img
              class="wheel_out"
              alt="หมุนโชควงล้อ"
              src="@/assets/wheel_img/wheel_back2.png"
            >
            <img
              class="wheel_logo"
              alt="หมุนโชควงล้อ"
              src="@/assets/wheel_img/wheel_logo.png"
            >
            <canvas
              id="canvas"
              width="950"
              height="950"
              class="wheel"
              data-responsiveminwidth="260"
              data-responsivescaleheight="true"
              data-responsivemargin="30"
            />
          </div>

          <div class="wheel-section mb-1">
            <button
              :disabled="check"
              class="btn-gray-txt p-25 btn-wheel"
              @click="$refs['modal-confirm-buy'].show()"
            >
              หมุนโชคกงล้อ
            </button>
            <h5 class="text-center text mt-25">
              พ้อยท์ของคุณคือ {{ mypointshow }} พ้อยท์
            </h5>
          </div>

          <div class="table-in">
            <h4 class="text-center text-primary m-0">
              ผู้โชคดีได้รับรางวัล
            </h4>

            <div class="container-sm-lot pl-1 pr-1">
              <div
                v-for="item in resdata"
                :key="item"
                class="htry-list mb-1 cursor-pointer"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-left">
                    <small class="mb-0 text-primary">
                      ชื่อผู้ใช้
                    </small>
                    <h4 class="text-primary font-weight-bolder mb-0">
                      {{ item.username }}
                    </h4>
                  </div>

                  <p class="mb-0 text-primary font-weight-bolder">
                    {{ item.bunus_text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Reward -->
    <b-modal
      ref="my-reward"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="text-center">
        <h3 class="font-weight-bolder text-primary mt-50">
          ยินดีด้วยค่ะ
        </h3>
        <div>
          <img
            src="@/assets/images/newIcon/welcom.png"
            alt="welcome"
            width="95"
          >
        </div>

        <p class="mb-0 text-primary font-weight-bolder">
          คุณ{{ dataupdate }}
        </p>

        <button
          class="btn-gray-txt p-25 w-50 my-1"
          @click="$refs['my-reward'].hide() , reload()"
        >
          ยืนยัน
        </button>
      </div>
    </b-modal>

    <!-- Modal used point -->
    <b-modal
      ref="modal-confirm-buy"
      hide-footer
      hide-header
      centered
      size="sm"
    >

      <div class="text-center">
        <h3 class="font-weight-bolder text-primary">
          ยืนยันการหมุนกงล้อ
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-center my-2">
        <p class="mb-0 font-weight-bolder text-primary">
          ใช้พ้อยท์
        </p>

        <input
          v-model="mypoint"
          disabled
          type="number"
          class="point-txt mx-1"
        >

        <p class="mb-0 font-weight-bolder text-primary">
          Point
        </p>
      </div>

      <div class="text-center">
        <button
          class="btn-confirm"
          @click="clicks()"
        >
          ยืนยัน
        </button>

        <button
          class="btn-del"
          @click="$refs['modal-confirm-buy'].hide()"
        >
          ยกเลิก
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas'
import Winwheel from './js/Winwheel'
// eslint-disable-next-line no-unused-vars
import TweenMax from './js/TweenMax'
// eslint-disable-next-line semi
// import ThisFooter from './components/ThisFooter.vue';

export default ({
  components: {
    // ThisFooter,
  },

  data() {
    return {
      mypoint: 10,
      check: false,
      loading: false,
      show: true,
      UserData: JSON.parse(localStorage.getItem('userData')),
      balance: JSON.parse(localStorage.getItem('balance')),
      fullbank: '',
      amount: '',
      wto: 'bank',
      theWheel: '',
      loadedImg: null,
      xhr: {
        readyState: 4,
        status: 200,
      },
      resdata: [],
      screenshot: null,
      dataupdate: null,
      mypointshow: 0,
    }
  },
  mounted() {
    this.GetShow()
    this.getBalance()
    this.theWheel = new Winwheel({
      numSegments: 8,
      drawMode: 'image',
      responsive: true,
      segments:
    [
      { text: 'เสียใจด้วยนะคะ' },
      { text: 'กล่องสุ่ม 10 ชุด' },
      { text: 'กล่องสุ่ม 1 ชุด' },
      { text: 'ได้รับ 5 พ้อยท์' },
      { text: 'กล่องสุ่ม 5 ชุด' },
      { text: 'ได้รับ 15 พ้อยท์' },
      { text: 'GALAXY Z FOLD 5 1TB' },
      { text: 'กล่องสุ่ม 2 ชุด' },
    ],
      animation:
    {
      type: 'spinToStop',
      duration: 5,
      spins: 8,
      callbackFinished: this.alertPrize,
    },
    })

    this.loadedImg = new Image()
    this.loadedImg.onload = () => {
      this.theWheel.wheelImage = this.loadedImg
      this.theWheel.draw()
    }
    // eslint-disable-next-line global-require
    this.loadedImg.src = require('@/assets/wheel_img/SPIN-02_1.png')
  },
  methods: {
    getBalance() {
      this.$http
        .get('balance/getbalance')
        .then(response => {
          this.mypointshow = response.data.point
        })
        .catch(error => {
          // จัดการข้อผิดพลาดในการส่งคำขอ HTTP อื่น ๆ ได้ที่นี่
          this.$router.push('/login') // สมมติว่ามีการใช้ Vue Router
          window.location.reload()
          console.error('Error:', error)
        })
    },
    takeScreenshot() {
      html2canvas(document.querySelector('#screenshot-section')).then(canvas => {
        // this.screenshot = canvas.toDataURL()
        this.Updatefile(canvas.toDataURL())
      })
    },
    GetShow() {
      this.$http
        .get('/luckywheel/index')
        .then(response => {
          this.resdata = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    clicks() {
      this.$refs['modal-confirm-buy'].hide()
      // const xhr = new XMLHttpRequest()
      // xhr.onreadystatechange = this.ajaxStateChange(1)
      this.check = true
      this.$http
        .get('/luckywheel')
        .then(response => {
          this.dataupdate = response.data.data
          // eslint-disable-next-line no-shadow
          const xhr = new XMLHttpRequest()
          xhr.onreadystatechange = this.ajaxStateChange(response.data.spin)
        })
        .catch(error => {
          this.check = false
          this.SwalError(error.response.data.message)
        })
    },
    Updatefile(img) {
      const Obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: this.dataupdate._id,
        screenshot: img,
      }
      this.$http
        .post('/luckywheel/UploadImg', Obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.resdata = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    ajaxStateChange(num) {
      const stopAt = this.theWheel.getRandomForSegment(num)
      this.theWheel.animation.stopAngle = stopAt
      this.theWheel.startAnimation()
    },
    async alertPrize(indicatedSegment) {
      // console.log(indicatedSegment)
      // this.takeScreenshot()
      // eslint-disable-next-line no-alert
      // alert(`คุณได้รับโบนัส : ${indicatedSegment.text}`)
      this.$refs['my-reward'].show(indicatedSegment)
      // await this.$swal({
      //   icon: 'success',
      //   title: `${indicatedSegment.text}`,
      //   showConfirmButton: true,
      //   // timer: 10000,
      //   customClass: {
      //     confirmButton: 'btn btn-success',
      //   },
      // })
      // eslint-disable-next-line no-restricted-globals
      // location.reload()
    },
    reload() {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: 'แจ้งเตือน !',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        // background: '#202124',
      })
    },
  },
})
</script>

<style scoped>
.the_wheel .wheel_out {
    width: 100%;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
  }

  .the_wheel .wheel_logo {
    width: 100%;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
  }

  .the_wheel {
    position: relative;
    z-index: 9;
    width: 100%;
    max-width: 500px;
}
canvas.wheel {
    width: 100%;
}
</style>
